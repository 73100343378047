/* Include Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Google fonts 
 - Roboto used for Google login
*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

/*
- Nunito sans used for the SaaS
*/
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');